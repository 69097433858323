import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/general/components/Button/Button.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/app/src/general/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatStatus"] */ "/app/src/general/components/Chat/ChatStatus.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/general/components/CircularProgress/CircularProgress.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleOneTapDynamic"] */ "/app/src/general/components/GoogleOneTap/GoogleOneTapDynamic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMenu"] */ "/app/src/general/components/HeaderMenu/HeaderMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KeywordSearchDynamic"] */ "/app/src/general/components/KeywordSearch/KeywordSearchDynamic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MicrocopyComponent"] */ "/app/src/general/components/Microcopy/MicrocopyComponent.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/general/components/PreviewIndicator/PreviewIndicator.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/general/components/Markdown/MarkdownCarousel.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/general/components/Markdown/Markdown.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/general/components/Shade/Shade.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoEmbed"] */ "/app/src/general/components/VideoEmbed/VideoEmbed.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/general/Icons/Icon.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/hygiene/sections/MediaSection/MediaSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/hygiene/sections/flexible-content/InlineImage/InlineImage.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/hygiene/sections/flexible-content/Awards/Awards.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/general/sections/Footer/Footer.module.scss");
