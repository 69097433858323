'use client';

import { FC, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { liveChatStore } from 'src/stores/liveChatStore';

type Props = {
  visibility: 'hidden' | 'minimized' | 'maximized';
};

export const ChatStatus: FC<Props> = ({ visibility }) => {
  const { hideLiveChat, minimiseLiveChat, maximiseLiveChat } = liveChatStore(
    ({ hideLiveChat, minimiseLiveChat, maximiseLiveChat }) => ({
      hideLiveChat,
      minimiseLiveChat,
      maximiseLiveChat,
    }),
    shallow,
  );
  useEffect(() => {
    switch (visibility) {
      case 'hidden':
        hideLiveChat();
        break;
      case 'minimized':
        minimiseLiveChat();
        break;
      case 'maximized':
        maximiseLiveChat();
        break;
    }
  }, [visibility]);

  return null;
};
