'use client';

import { clsx } from 'clsx';
import { isEmpty } from 'lodash';
import { FC, useCallback, useState } from 'react';

import { Image } from 'src/general/components/Image/Image';
import { getVideoEmbed } from './VideoEmbed.helper';
import styles from './VideoEmbed.module.scss';
import playButton from './images/play-button.svg';

type Props = {
  width?: number;
  height?: number;
  videoUrl: string;
  thumbnailImageUrl: string;
  setButtonCentered?: boolean;
};

/**
 * Dynamically embeds a Youtube or a Vimeo video with a play button
 */
export const VideoEmbed: FC<Props> = ({ height, thumbnailImageUrl, videoUrl, width, setButtonCentered }) => {
  const [onClickPlay, setOnClickPlay] = useState<boolean>(false);

  const onImageClick = useCallback(() => {
    setOnClickPlay(true);
  }, []);

  const videoEmbed = getVideoEmbed(videoUrl, onClickPlay);

  if (!videoEmbed) {
    return null;
  }

  return (
    <div className={styles.root}>
      {onClickPlay || isEmpty(thumbnailImageUrl) ? (
        <iframe
          width={width ?? 1280}
          height={height ?? 720}
          src={videoEmbed}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded video"
          className={clsx(styles.video, 'video')}
        />
      ) : (
        <button className={styles.customImageAndPlayButton} onClick={onImageClick} data-testid="VIDEO_TRIGGER">
          <Image
            src={thumbnailImageUrl}
            width={width ?? 1280}
            height={height ?? 720}
            alt="Play video"
            className={styles.image}
          />
          <div className={clsx(styles.buttonContainer, { [styles.setButtonCentered]: setButtonCentered })}>
            <Image src={playButton} alt="Video play button" width={120} height={120} className={styles.playButton} />
          </div>
        </button>
      )}
    </div>
  );
};
