import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconCaretLeft: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="caret-left">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.998 5.151-6.424 6.425a.6.6 0 0 0 0 .848l6.424 6.424.849-.848-6-6 6-6-.849-.849Z"
    />
  </Icon>
);
