'use client';

import { FC, PropsWithChildren, Suspense, lazy, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { COOKIE_MICROCOPY_DEBUG } from 'src/constants';
import { MicrocopyKey } from 'src/data/microcopy/microcopyDictionary';

interface Props {
  value: MicrocopyKey;
  parseMarkdown?: boolean;
  microcopyTokens?: { [token: string]: string };
}

const MicrocopyDebug = lazy(() => import('./MicrocopyDebug'));

export const MicrocopyComponent: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const [showDebug, setShowDebug] = useState<boolean>(false);

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get(COOKIE_MICROCOPY_DEBUG)) {
      setShowDebug(true);
    }
  }, []);

  if (showDebug) {
    return (
      <Suspense>
        <MicrocopyDebug {...props} />
      </Suspense>
    );
  }

  return children;
};
