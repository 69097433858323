export const getVideoYoutubeEmbedId = (url: string) => {
  const regExp = /(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*)/;
  const match = regExp.exec(url);
  return match?.[1] ? match[1] : false;
};

const getVideoYoutubeEmbed = (url: string, onClickPlay?: boolean): string | null => {
  const embedId = getVideoYoutubeEmbedId(url);
  if (!embedId) {
    return null;
  }
  const iframeSrc = new URL(`https://www.youtube.com/embed/${embedId}`);
  iframeSrc.searchParams.append('autoplay', onClickPlay ? '1' : '0');
  iframeSrc.searchParams.append('playsinline', '1');
  iframeSrc.searchParams.append('controls', '1');
  iframeSrc.searchParams.append('rel', '0');

  return iframeSrc.toString();
};

export const getVideoVimeoEmbedId = (url: string) => {
  const regExp = /(?:vimeo.com\/|video\/)(\d+)/;
  const match = regExp.exec(url);
  return match?.[1] ? match[1] : false;
};

const getVideoVimeoEmbed = (url: string, onClickPlay?: boolean): string | null => {
  const embedId = getVideoVimeoEmbedId(url);
  if (!embedId) {
    return null;
  }
  const iframeSrc = new URL(`https://player.vimeo.com/video/${embedId}`);
  iframeSrc.searchParams.append('autoplay', onClickPlay ? '1' : '0');
  iframeSrc.searchParams.append('badge', '0');
  iframeSrc.searchParams.append('autopause', '0');
  iframeSrc.searchParams.append('player_id', '0');
  iframeSrc.searchParams.append('app_id', '58479');
  iframeSrc.searchParams.append('playsinline', '1');

  return iframeSrc.toString();
};

export const getVideoEmbed = (url: string, onClickPlay?: boolean): string | null => {
  const youtubeEmbed = getVideoYoutubeEmbed(url, onClickPlay);
  if (youtubeEmbed) {
    return youtubeEmbed;
  }
  return getVideoVimeoEmbed(url, onClickPlay);
};
