import { FC } from 'react';

import { Icon } from './Icon';
import { SvgIcon } from './SvgIcon.type';

export const IconCaretRight: FC<SvgIcon> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" name="caret-right">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9 5.151 6.425 6.425a.6.6 0 0 1 0 .848l-6.424 6.424L8.152 18l6-6-6-6 .849-.849Z"
    />
  </Icon>
);
